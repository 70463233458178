@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* font-family: 'Saira', sans-serif; */

/* Iphone 12Pro and smaller */
@media only screen and (max-width: 390px) {

    .homeContainer {
        font-family: 'Saira', sans-serif;
        text-align: center;
        font-weight: 400;
        /* border-width: 1px; */
        /* border-color: black; */
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto 700px auto;
        grid-template-areas:
            ".   nav   .  "
            ".  comp1  .  "
            ".  comp2  . "
        


    }

    /* NAV AREA */
    .navItems {
        /* position: fixed;  */
        top: 10px;
        width: 100%;
        /* background-color: rgb(91, 91, 91); */
        overflow: hidden;

        grid-area: nav;
        display: grid;

        font-size: 35px;
        grid-template-columns: auto;
        grid-template-rows: auto;

        grid-template-areas:
            "df . . . ."



    }
    .df {
        grid-area: df;
        margin-top: 25px;
    }
    .item1 {

        /* border-width: 1px; */
        /* border-color: black; */
        font-size: 10px;
        font-weight: 500;

        grid-area: item1;
    }

    .item2 {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item2;
    }

    .item3 {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item2;
    }

    /* COMP1 */
    .comp1 {
    
        grid-area: comp1;
    }

    /* COMP2 */
    .comp2 {

        grid-area: comp2;
    }

    /* COMP3 */
    .comp3 {
        border-width: 1px;
        border-color: black;
        grid-area: comp3;

    }

    .comp4 {
        border-width: 1px;
        border-color: black;
        grid-area: comp4;

    }

    /* FTER */
    .fter {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: fter;
    }

}










@media only screen and (min-width: 391px) {

    .homeContainer {
        font-family: 'Saira', sans-serif;
        text-align: center;
        font-size: 40px;
        font-weight: 400;

        /* border-width: 1px; */
        /* border-color: black; */
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto 750px auto;
        grid-template-areas:
            ".   nav   .  "
            ".  comp1  .  "
            ".  comp2  . "
        


    }

    /* NAV AREA */
    .navItems {
        /* position: fixed;  */
        top: 10px;
        width: 100%;
        /* background-color: rgb(91, 91, 91); */
        overflow: hidden;

        grid-area: nav;
        display: grid;

        font-size: 40px;
        grid-template-columns: auto;
        grid-template-rows: auto;

        grid-template-areas:
            "df . . . ."



    }
    .df {
        grid-area: df;
        margin-top: 25px;
    }
    .item1 {

        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item1;
    }

    .item2 {


        /* border-width: 1px; */
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item2;
    }

    .item3 {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item2;
    }

    /* COMP1 */
    .comp1 {
        grid-area: comp1;
    }

    /* COMP2 */
    .comp2 {

        grid-area: comp2;
    }

    /* COMP3 */
    .comp3 {
        border-width: 1px;
        border-color: black;
        grid-area: comp3;

    }

    .comp4 {
        border-width: 1px;
        border-color: black;
        grid-area: comp4;

    }

    /* FTER */
    .fter {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: fter;
    }

}



@media only screen and (min-width: 629) {

    .homeContainer {
        font-family: 'Saira', sans-serif;
        text-align: center;
        font-size: 40px;
        font-weight: 400;

        /* border-width: 1px; */
        /* border-color: black; */
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            ".   nav   .  "
            ".  comp1  .  "
            ".  comp2  . "
        


    }

    /* NAV AREA */
    .navItems {
        /* position: fixed;  */
        top: 10px;
        width: 100%;
        /* background-color: rgb(91, 91, 91); */
        overflow: hidden;

        grid-area: nav;
        display: grid;

        font-size: 40px;
        grid-template-columns: auto;
        grid-template-rows: auto;

        grid-template-areas:
            "df . . . ."



    }
    .df {
        grid-area: df;
        margin-top: 25px;
    }
    .item1 {

        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item1;
    }

    .item2 {


        /* border-width: 1px; */
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item2;
    }

    .item3 {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: item2;
    }

    /* COMP1 */
    .comp1 {
       
        grid-area: comp1;
    }

    /* COMP2 */
    .comp2 {

        grid-area: comp2;
    }

    /* COMP3 */
    .comp3 {
        border-width: 1px;
        border-color: black;
        grid-area: comp3;

    }

    .comp4 {
        border-width: 1px;
        border-color: black;
        grid-area: comp4;

    }

    /* FTER */
    .fter {


        border-width: 1px;
        border-color: black;
        font-size: 10px;
        font-weight: 500;

        grid-area: fter;
    }

}

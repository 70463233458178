@media only screen and (min-width: 391px) {
    .introContainer {
        font-family: 'Saira', sans-serif;
        font-size: 22px;
        font-weight: 400;
        margin-top: 140px;
        display: grid;
        text-align: center;
        /* border-width: 1px; */
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            ". it1 ."
            ". it2 ."
            ". cons ."
    }




    .introTitle {
        /* margin-top: 20px; */
        text-align: center;
        font-size: 45px;
        font-weight: 500;
        font-family: 'Saira', sans-serif;

        position: relative;
        /* left: 15px; */
        top: 120px;

    }

    .intro1 {
        text-align: center;
        padding-top: 30px;

        grid-area: it1;
    }

    .intro2 {
        padding-top: 20px;

        text-align: center;

        grid-area: it2;
    }




    .cons {
        display: grid;

        grid-area: cons;
        /* border-width: 1px; */
        margin-right: 10rem;
        margin-left: 10rem;

        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
            " cc1 ic1 ic2 ic3 ic4"
    }

    .cc1 {
        /* border-width: 1px; */

        text-align: center;
        position: relative;

        margin-top: 25px;
        grid-area: cc1;
    }

    .ic1 {
        /* border-width: 1px; */

        margin: auto;
        position: relative;

        margin-top: 25px;
        padding: 5px;


        grid-area: ic1;
    }

    .ic2 {
        margin: auto;



        margin-top: 25px;
        padding: 5px;


        grid-area: ic2;
    }

    .ic3 {
        margin: auto;



        margin-top: 25px;
        padding: 5px;


        grid-area: ic3;
    }

    .ic4 {
        /* border-width: 1px; */

        margin: auto;


        margin-top: 25px;
        padding: 5px;


        grid-area: ic4;
    }
}


/* IPhone 12 Pro */
@media only screen and (max-width: 390px) {
    .introContainer {
        font-family: 'Saira', sans-serif;
        font-size: 20px;
        font-weight: 400;
        margin-top: 160px;
        display: grid;
        /* position: relative; */
        /* border-width: 1px; */
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            ". it1 ."
            ". it2 ."
            ". cons ."
    }

    .introTitle {
        /* margin-top: 20px; */
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        font-family: 'Saira', sans-serif;

        position: relative;
        /* left: 15px; */
        top: 140px;

    }

    .intro1 {
        text-align: center;
        padding-top: 30px;

        grid-area: it1;
    }

    .intro2 {
        padding-top: 20px;

        text-align: center;

        grid-area: it2;
    }


    .cons {
        display: grid;

        grid-area: cons;
        /* border-width: 1px; */
        margin-left: 2rem;
        margin-right: 2rem;




        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
            " cc1 ic1 ic2 ic3 ic4"
    }


    .cc1 {
        text-align: center;
        position: relative;

        margin-top: 25px;
        grid-area: cc1;
    }

    .ic1 {
        margin: auto;
        position: relative;

        margin-top: 25px;
        padding: 5px;


        grid-area: ic1;
    }

    .ic2 {
        margin: auto;



        margin-top: 25px;
        padding: 5px;


        grid-area: ic2;
    }

    .ic3 {
        margin: auto;



        margin-top: 25px;
        padding: 5px;





        grid-area: ic3;
    }

    .ic4 {
        margin: auto;



        margin-top: 25px;
        padding: 5px;





        grid-area: ic4;
    }
}

@media only screen and (min-width: 390px) and (max-width:560px) {
    .cons {
        display: grid;

        grid-area: cons;
        /* border-width: 1px; */
        margin-left: 4rem;
        margin-right: 4rem;




        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
            " cc1 ic1 ic2 ic3 ic4"
    }
}